/*
* Login Wrapper Section
*/

.login-lockscreen-logo {
    line-height: 0.6;
    margin-bottom: 0;
}

.login-company-logo {
    height: 100px;
    margin-top: 30px;
    border-radius: 0px;
    margin-bottom: 10px;
    background-color: initial;
}

.lockscreen-wrapper {
    background-color: #FFF;
    max-width: 526px!important;
    height: auto!important;
    margin: 0 auto;
    margin-top: 5%!important;
    border-radius: 20px;
    border-top: 5px solid #FFF;
    margin: auto auto !important;
}


.login-title-div {
    margin-top: 20px;
    margin-bottom: 0;
    color: #1b2331;
    font-size: 22px;
    font-family: "Manrope";
    font-weight: 700;
    line-height: 24px;
}

.login-page-login-ccm-title {
    color: #DFE6F1!important;
    font-size: 32px;
    font-weight: 700;
    font-family: "Montserrat",sans-serif;
    width: 537px;
    margin-bottom: 40px;
}

.login-row {
    margin-top: 43px!important;
}

button.btn.btn-login {
    color: #1B2331;
    background-color: #51F895;
    font-weight: bold;
    height: 36px;
    border-radius: 20px;
}

.btn-login:hover {
    color: inherit;
    background-color: #c3fedb;
    font-weight: bold;
}

.login-page-ccm-title-div {
    position: absolute;
    margin-left: 48px;
	/* margin-top: 440px; */
    /* margin-top: 32vw; */
    bottom: 0;
}

.separator-container {
    display: flex;
    align-items: center;
    padding-top: 28px;
    align-self: stretch;

}

.separator {
    flex: 1;
    height: 1px;
    background-color: #DFE6F1;
}

.separator-text {
    color: var(--neutral-mirage-500, #455573);
    font-family: Inter, Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.login-page-app-version {
    visibility: hidden;
}

.try-compose-text {
    color: var(--neutral-mirage-700-p, #1B2331);
    text-align: center;
    font-family: Inter, Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding-top: 20px;
}

.try-compose-text a {
    color: #1B2331;
    text-decoration: underline;
    font-weight: 500;
}

.try-compose-text a:hover {
    text-decoration: none;
}

.app-version-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-page-title {
    color: #455573;
    font-size: 14px;
    font-family: Inter, "Manrope","Montserrat",sans-serif;
    margin-top: 8px;
    line-height: 20px;
    letter-spacing: 0.1px;
    font-weight: 400;
}

/* Language style selection */


.lang-div {
    text-align: right;
    padding-right: 32px;
    padding-top: 30px;
}

.lang-eng-span {
    margin-right: 10px;
}

/*
* Login Header Section
*/

.login-main-header {
    background-color: transparent;
    margin-top: 40px;
    margin-left: 30px;
    padding-bottom: 32px;
}

.skin-default .main-header .login-logo {
    background-color: transparent;
}

.skin-default .main-header .logo:hover {
    background-color: transparent;
}

.login-logo-img {
    height: 54px;
    width: 177px;
}

.skin-default .main-header .login-navbar-static-top {
    background-color: transparent;
}

.login-user-menu {
    border: 2px solid white;
    border-radius: 20px;
    margin-right: 30px;
}

.login-page-login-ccm-title div a {
    text-decoration: none;
}

.login-hidden-xs {
    font-family: "Inter", Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    color: #AEBDD5;
}

.login-empty-div {
    width: 20px;
    border: 1px;
}

/*
* Login Footer Section
*/

.login-footer-div {
    margin-top: auto;
}

.login-main-footer {
    margin-left: 0;
    background-color: transparent;
    border: none;
}

.login-footer-div a {
    color: #455573;
    text-decoration: underline;
}

.login-footer-div a:hover {
    text-decoration: none;
}


.login-my-auto {
    color: var(--neutral-mirage-500, #455573);
    font-family: Inter, Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.login-compose-logo-icon {
    width: 20px;
    margin: 0px 5px 0px -5px;
}